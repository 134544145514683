@import "src/styles/variabels";

.root-left-sidebar {
  color: #fff;
  .menu-controls {
    min-height: 6.8rem;
    .MuiButtonBase-root {
      width: 6.4rem;
      height: 6.4rem;
      color: #fff;
      border-radius: 0;
    }
  }
  .user-nav {
    min-height: 6.9rem;
    .user-button {
      min-height: 5.2rem;
      .MuiButton-label .MuiButton-startIcon svg {
        color: #fff;
      }
    }
  }
  .root-icons-nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        line-height: 1;
        text-align: center;
        a {
          display: inline-block;
          margin: 0 0 2rem;
          cursor: pointer;
          img {
            display: block;
            max-width: 2.4rem;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
          }
        }
        &:hover {
          opacity: .75;
        }
      }
    }
  }
}
#root .root-drawer {
  width: $drawer-width-close;
  > div {
    width: $drawer-width-close;
    box-sizing: content-box;
    .logo,
    .user-nav,
    .main-nav,
    .copyright {
      width: $drawer-width-open;
    }
  }
  .logo {
    min-height: 6.8rem;
    position: relative;
    padding: 1.3rem 0 0 2rem;
    box-sizing: border-box;
    svg {
      max-height: 3.8rem;
      width: auto;
      opacity: 0;
      transform: translateX(-5.1rem);
      transition: opacity .25s ease-in-out, transform .25s ease-in-out;
      &:nth-child(2) {
        position: absolute;
        top: 1.3rem;
        left: 0;
        opacity: 1;
        transform: translateX(1.2rem);
        transition: opacity .25s ease-in-out, transform .25s ease-in-out;
      }
    }
  }
  .user-nav {
    .user-button {
      min-height: 5.2rem;
    }
    &.hide-avatar {
      .MuiButton-label {
        padding-left: 2rem;
      }
    }
    .user-button-title {
      transition: color .25s ease-in-out;
      color: #fff;
      strong {
        display: inline-block;
        position: relative;
        padding-right: 3rem;
        transform: translateX(calc(-100% + 2.5rem));
        transition: transform .25s ease-in-out;
        &:before {
          content: '';
          position: absolute;
          top: .7rem;
          right: 1rem;
          border-top: .5rem solid #8a8a8a;
          border-left: .4rem solid transparent;
          border-right: .4rem solid transparent;
          transform: translateY(.7rem);
          transition: transform .25s ease-in-out;
        }
      }
      span {
        display: block;
        transform: translateX(calc(-100% + 2.5rem));
        transition: transform .25s ease-in-out;
      }
    }
  }
  .main-nav {
    color: $color-default;
    .active {
      background: none;
      .MuiListItemText-secondary {
        opacity: .7;
      }
      > .icon-wrapper svg,
      > .title .MuiListItemText-primary {
        color: $color-secondary;
      }
    }
    .icon-wrapper {
      min-width: 6.4rem;
      svg {
        transform: scale(1.455);
        transition: transform .25s ease-in-out;
      }
    }
    .level-1 {
      min-height: 6rem;
      position: relative;
      .MuiListItemText-primary {
        text-transform: uppercase;
      }
      .MuiListItemText-multiline {
        margin: 0;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #EAEAEA;
      }
      &:last-child:before {
        display: none;
      }
      .title {
        font-size: 1.6rem;
        .MuiListItemText-primary {
          font-weight: 500;
        }
      }
    }
    .level-2 {
      .title {
        padding-left: 4.9rem;
      }
    }
    .MuiListItem-button {
      &:hover {
        background-color: transparent;
        color: $color-secondary;
        .MuiListItemText-secondary {
          opacity: .7;
        }
      }
    }
    .title {
      .MuiListItemText-primary {
        color: $color-default;
      }
      .MuiListItemText-secondary {
        font-size: 1.1rem;
        opacity: .7;
        padding-top: .3rem;
      }
    }
    .MuiList-root .MuiList-root {
      padding-top: 1rem;
      padding-bottom: .5rem;
      .title {
        margin-top: 0;
        margin-bottom: 0;
        .MuiListItemText-primary {
          color: $color-default;
          font-size: 1.4rem;
        }
      }
    }
  }
  .copyright {
    color: #888D8F;
    font-size: 1.1rem;
    min-height: 5rem;
    max-height: 5rem;
    max-width: 20rem;
    white-space: normal;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    div {
      position: absolute;
      top: .5rem;
      left: 2rem;
    }
  }
  &.opened {
    width: $drawer-width-open;
  }
  &.opened,
  &.hover {
    > div {
      width: $drawer-width-open;
    }
    .logo {
      svg:nth-child(1) {
        transform: translateX(0);
        opacity: 1;
      }
      svg:nth-child(2) {
        opacity: 0;
        transform: translateX(5.6rem);
      }
    }
    .user-nav {
      .user-button-title {
        color: #434343;
        strong, span {
          transform: translateX(0);
          &:before {
            transform: translateY(0);
          }
        }
      }
    }
    .main-nav {
      .icon-wrapper {
        svg {
          transform: scale(1);
        }
      }
      .level-1 {
        &:before {
          left: 6.4rem;
        }
      }
    }
    .copyright {
      opacity: 1;
    }
  }
}
.main-nav > .level-3 .title .active {
  > .icon-wrapper svg,
  .title .MuiListItemText-primary {
    color: $color-secondary;
  }
}
