@import './variabels';
.data-grid {
  padding: 2.4rem 3rem 3rem;
  .data-grid-wrapper {
    border-color: $color-border;
    border-radius: 0;
    padding: 2rem;
  }
  .toolbar-head {
    padding: 0 0 2rem;
    .title {
      font-weight: bold;
    }
    .toolbar-controls li {
      button {
        height: 3.6rem;
        min-width: 10rem;
      }
    }
  }
  .toolbar-filters {
    background: #F3F8FA;
    border-radius: 0;
    margin: 0 0 1.5rem;
    padding: 2rem 2rem .5rem;
    svg {
      color: $color-secondary;
    }
  }
  .data-grid-scroll {
    &:hover {
      & > :nth-child(2) {
        z-index: 22;
      }
    }
    table {
      thead tr th {
        color: #8A8F91;
        font-weight: 500;
        line-height: 2.4rem;
        &:first-child {
          padding-left: .5rem;
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: .5rem;
              &.is-edit-allow {
                padding-left: 1rem;
              }
            }
            &.swap {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .data-grid-pagination {
    color: $color-table;
    .MuiFormControl-root {
      max-width: 8rem;
    }
    .size .total {
      span {
        font-weight: 600;
      }
    }
    .pagination {
      .disabled {
        display: none;
      }
      li {
        padding: 0 .3rem;
        a {
          color: #95A2A7;
          padding: .3rem .5rem;
          border-radius: 0;
          &:hover {
            color: $color-secondary;
          }
        }
        &.active a {
          box-shadow: none;
          background: $color-secondary;
          min-width: 3.6rem;
          color: #fff;
        }
      }
    }
  }
}
.grid-inner{
  padding: 0;
  height: 100%;
  .data-grid-wrapper{
    padding: 0;
    border: none;
  }
}
.grid-toolbar-filters{
  .toolbar-head {
    top: 2.5rem;
    right: 2rem;
    position: absolute;
  }
}
.data-grid-wrapper-style > .grid-footer > .pagination > li > a {
  &:hover,
  &.active {
    color: $color-secondary;
  }
}

.data-grid{
  &__pagination-item{
    &_active{
      color: $color-secondary;
    }
  }
}
