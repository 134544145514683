$color-primary: #5FB800;
$color-secondary: #36587A;
$color-secondary-light: rgba($color-secondary, 0.05);

$color-default: #434343;
$color-border: #EAEAEA;
$color-table: #888D8F;


$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorTable: $color-table;

  drawerWidthOpen: $drawer-width-open;
  drawerWidthClose: $drawer-width-close;
}
